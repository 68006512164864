import store from "@/store";
import DownloadService from "@/services/download-service";

class DownloadHelper {
  /**
   * Downloads the specified invoice as a PDF file
   */
  async downloadInvoice(invoiceNo: string) {
    store.commit("showSpinner", true);

    const result = await DownloadService.downloadInvoice(invoiceNo)
      .then(() => {
        store.commit("showSpinner", false);
      })
      .catch((e: Error) => {
        console.error(e);
      });
    return result;
  }
}

export default new DownloadHelper();
